import React from 'react';
import '../styles/Footer.css';

const FooterComponent: React.FC = () => {
  return (
    <footer className="footer">
      <span>© 2024 BloomByte, LLC</span>
      <a href="#">Terms of Service</a>
      <a href="#">Privacy Policy</a>
    </footer>
  );
};

export default FooterComponent;