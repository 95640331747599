import React from 'react';
import styled from 'styled-components';
import Navbar from './components/Navbar';
import Content from './components/MainContent';
import FooterComponent from './components/Footer';

const Container = styled.div`
  background-color: #ffffff;
  color: #e56d90;
  font-family: 'Helvetica Neue', sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const App: React.FC = () => {
  return (
    <Container>
      <Navbar />
      <Content />
      <FooterComponent />
    </Container>
  );
};

export default App;