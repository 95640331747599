import React from 'react';
import '../styles/MainContent.css';
import logoImage from '../logo.png';

const Content: React.FC = () => {
  return (
    <div className="main-content">
      <img src={logoImage} alt="BloomByte Logo" className="logo" />
      <h2 className="subtitle">Transforming with Technology</h2>
    </div>
  );
};

export default Content;