import React from 'react';
import '../styles/Navbar.css';

const Navbar: React.FC = () => {
  return (
    <nav className="navbar">
      <a href="#" className="nav-link">Home</a>
      <a href="#" className="nav-link">Products</a>
    </nav>
  );
};

export default Navbar;